<template>
  <!--begin::Card-->
  <div>
    <h4 class="mb-10 font-weight-bold text-dark">
      Menu <br />
      <small>Please enter your menu information.</small>
    </h4>
    <form
      class="form"
      novalidate="novalidate"
      id="menu_form"
      ref="form"
      v-on:submit="save"
    >
      <div class="form-group">
        <label>Text For Right Hand Side of Menu</label>
        <b-form-textarea
          id="textarea"
          v-model="form.menu_text"
          placeholder="Enter something..."
          rows="3"
          max-rows="6"
        ></b-form-textarea>
        <span class="form-text text-muted"
          >This text will appear below the payment symbols to the right of the
          menu page.</span
        >
      </div>
      <button
        :style="{'display': $route.name === 'wizard-menu-details' ? 'block' : 'none'}"
        ref="form_submit"
        class="btn btn-primary mt-3 mr-4"
      >
        Submit
      </button>
    </form>
  </div>
  <!--end::Card-->
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Swal from "sweetalert2";
// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import { UPDATE_PERSONAL_INFO } from "@/core/services/store/profile.module";
export default {
  name: "Menu",
  computed: {
    ...mapGetters(["currentUser", "currentUserPersonalInfo"]),
  },
  data() {
    return {
      form: {
        menu_text: "",
      },
    };
  },
  mounted() {
    this.form.menu_text = this.currentUserPersonalInfo.menu_details;
    const form = KTUtil.getById("menu_form");
    this.fv = formValidation(form, {
      fields: {
        menu_text: {
          validators: {
            notEmpty: {
              message: "Menu is required",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });
    //console.log(this.fv.on("core.form.valid"));
    this.fv.on("core.form.valid", () => {
      this.$emit("validation", true);
    });

    this.fv.on("core.form.invalid", () => {
      this.$emit("validation", false);
    });
  },
  methods: {
    save() {
      let currentUserId = this.currentUser.id;
      ApiService.post(
        "business/settings/wizard/store/menu-setting/" + currentUserId,
        this.form
      )
        .then(({ data }) => {
            sessionStorage.removeItem("personalInfo");
            this.$store.dispatch(UPDATE_PERSONAL_INFO);
          if  (this.$route.name === 'wizard-menu-details') {
            Swal.fire("Success", "Menu Details updated successfully", "success");
          }
          return true;
        })
        .catch(({ response }) => {
          return false;
        });
    },
    cancel() {
      //
    },
    onSubmit() {
      this.$refs.form_submit.click();
    },
  },
};
</script>
